<template>
  <v-container
    id="wizard"
    tag="section"
  >
    <validation-observer
      ref="observer" 
      v-slot="{ valid }"
    >
      <base-material-wizard
        v-model="tab"
        :available-steps="availableSteps"
        :items="tabs"
        class="mx-auto"
        @click:next="next(valid)"
        @click:prev="tab--"
      >
        <v-tabs-items
          v-model="tab"
          class="pt-12 transparent"
        >
          <v-tab-item class="pb-12">
            <form>
              <div class="text-center display-1 font-weight-light mb-6">
                Escoha a Conta que será utilizada
              </div>

              <v-row
                class="mx-auto"
                justify="space-around"
                style="max-width: 1000px;"
              >
                <v-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="account"
                  >
                    <v-select
                      v-model="account"
                      :error-messages="errors"
                      :items="accounts"
                      item-text="account"
                      item-value="id"
                      dense
                      label="Escolha a Conta"
                      validate-on-change
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row
                v-show="account"
                no-gutters
              >
                <v-col
                  cols="6"
                  style="border-right: 1px solid #ccc"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="usarComoTabelaGeral"
                  >
                    <v-checkbox
                      v-model="usarComoTabelaGeral"
                      :error-messages="errors"
                      input-value="true"
                      label="Tabela geral para a Conta"
                      value
                      class="mt-0 ml-4"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <v-list
                    v-show="!usarComoTabelaGeral"
                    subheader
                    two-line
                    flat
                  >
                    <v-subheader>Escolha o usuário da conta para anexar a Tabela</v-subheader>
                    <v-list-item
                      v-for="usr in users"
                      :key="usr.id"
                      @click="userSelected(usr)"
                    >
                      <v-list-item-icon>
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            color="primary"
                          />
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>
                            {{ usr.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ usr.email }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </form>
          </v-tab-item>
<!-- 
          <v-tab-item class="pb-12">
            <form>
              <v-responsive
                class="mx-auto"
                max-width="1000"
              >
                <div class="text-center display-1 text--primary font-weight-light mb-2">
                  Qual operação você irá realizar?
                </div>
                <v-row>
                  <v-col cols="12">
                    <v-item-group
                      v-model="mode"
                      class="row"
                    >
                      <v-item
                        v-for="(mode, i) in modes"
                        :key="i"
                        :value="mode.type"
                      >
                        <template v-slot="{ active, toggle }">
                          <v-col
                            class="text-center"
                            cols="4"
                          >
                            <v-card
                              :class="active ? 'success--text' : 'grey--text'"
                              class="mb-4 mx-auto mt-0 pa-10 d-inline-flex v-card--account"
                              outlined
                              @click="toggle"
                            >
                              <v-icon
                                size="64"
                                class="mx-auto"
                                v-text="mode.icon"
                              />
                            </v-card>
                            <div
                              :class="active ? 'success--text' : 'grey--text'"
                              class="text-uppercase subtitle-2"
                              v-text="mode.label"
                            />
                          </v-col>
                        </template>
                      </v-item>
                    </v-item-group>
                  </v-col>
                </v-row>
              </v-responsive>
            </form>
          </v-tab-item> -->
<!-- 
          <v-tab-item
            v-if="modeSelected === 'list'"
            class="pb-12"
          >
            <div class="text-center display-2 primary--text font-weight-light mb-6">
              Listagem de Tabelas Associadas
            </div>
          </v-tab-item>
           -->
          <v-tab-item
            class="pb-12"
          >
            <div class="text-center display-2 primary--text font-weight-light mb-6">
              Enviar Tabela de Mapeamento
            </div>
            <v-card-text v-show="uploadFinished === true">
              Arquivo enviado com sucesso.
            </v-card-text>
            <form v-show="uploadFinished === false">
              <v-row
                class="mx-auto"
                justify="space-around"
                style="max-width: 1000px;"
              >
                <v-col
                  cols="8"
                  class="text-center"
                >
                  <template>
                    <validation-provider
                      v-slot="{ errors }"
                      :rules="{ required: filesCount }"
                    >
                      <input
                        ref="file"
                        type="file"
                        class="d-none"
                      >
                      <v-file-input
                        v-model="files"
                        accept=".json, .csv,"
                        label="Carregar Tabela JSON"
                        prepend-icon="mdi-cloud-upload"
                        :placeholder="fileLoaderPlaceholder"
                        outlined
                        :show-size="1000"
                        counter
                        :loading="fileIsLoading"
                        :disabled="fileLoaderIsDisabled"
                        class="display-3"
                        multiple
                        :error-messages="errors"
                        @change="onChange"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            class="display-3"
                          >
                            {{ text }}
                          </v-chip>
                          <span
                            v-else-if="index === 2"
                            class="overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} Arquivo(s)
                          </span>
                        </template>
                      </v-file-input>
                    </validation-provider>
                  </template>
                  <div class="font-weight-bold grey--text">
                    Carregar Arquivo JSON
                  </div>
                </v-col>
              </v-row>
              <v-row class="ml-2">
                <v-col
                  cols="12"
                  md="6"
                  sm="6"
                >
                  <v-subheader class="display-1">
                    Descrição
                  </v-subheader>
                  <v-text-field
                    v-model="description"
                    label="Adicione uma descição para tabela"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  sm="6"
                >
                  <v-subheader class="display-1">
                    Tipo de Produto
                  </v-subheader>
                  <v-radio-group
                    v-model="type"
                    column
                    class="ml-4 mt-0 radio-inline"
                  >
                    <v-radio
                      label="Multifocal"
                      :value="0"
                    />
                    <v-radio
                      label="Simples"
                      :value="1"
                    />
                    <v-radio
                      label="Ocupacional"
                      :value="2"
                    />
                    <v-radio
                      label="Bifocal"
                      :value="3"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>  
            </form>
          </v-tab-item>

          <!-- <v-tab-item
            v-else
            class="pb-12"
          >
            <div class="text-center display-2 primary--text font-weight-light mb-6">
              Desassociar Tabela de Mapeamento
            </div>
          </v-tab-item> -->

          <v-tab-item class="pb-12">
            <v-responsive
              class="mx-auto"
              max-width="1000"
            >
              <div class="text-center display-1 text--primary font-weight-light mb-2">
                Operação Finalizada com sucesso!
              </div>
              <v-row>
                <v-col
                  cols="12"
                  class="text-center"
                >
                  <v-btn
                    @click="reloadWizard()"
                  >
                    Iniciar uma nova Operação
                  </v-btn>
                </v-col>
              </v-row>
            </v-responsive>
          </v-tab-item>
        </v-tabs-items>
      </base-material-wizard>
    </validation-observer>

  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  import axios2 from '@/axios2';
  import { baseApiUrl } from '@/global'
import { AccountService } from '@/code/services/user/accountService'

  export default {
    name: 'DashboardFormsWizard',
    data () {
      return {
        formTitle: 'Tabelas De-Para',
        active: false,
        mode: 'link',
        modes: [
          {
            icon: 'mdi-folder-open',
            type: 'list',
            label: 'Listar Tabelas',
          },
          {
            icon: 'mdi-link-variant-plus',
            type: 'link',
            label: 'Adicionar Tabelas',
          },
          {
            icon: 'mdi-link-variant-minus',
            type: 'unlink',
            label: 'Desassociar Tabelas',
          },
        ],
        usarComoTabelaGeral: true,
        account: '',
        description: '',
        type: '',
        user: '',
        settings: [],
        accounts: [],
        accountsItems: [
          {
            icon: 'mdi-pencil',
            type: 'design',
          },
          {
            icon: 'mdi-code-tags',
            type: 'code',
          },
          {
            icon: 'mdi-laptop',
            type: 'develop',
          },
        ],
        uploadFinished: false,
        files: [],
        image: null,
        last: '',
        tab: null,
        tabs: [
          {
            text: 'Escopo',
            icon: 'mdi-badge-account-outline',
            scope: 'escopo',
          },
          // {
          //   text: 'Operação',
          //   icon: 'mdi-progress-wrench',
          //   scope: 'operacao',
          // },
          {
            text: 'Ação',
            icon: 'mdi-clipboard-flow-outline',
            scope: 'acao',
          },
          {
            text: 'Resumo',
            icon: 'mdi-trophy-award',
            scope: 'concluido',
          },
        ],
        fileIsLoading: false,
        fileLoaderIsDisabled: false,
        fileLoaderPlaceholder: 'Escolher arquivo'
      }
    },
    computed: {
      ...mapState([
        'users',
      ]),
      filesCount () {
        return this.files.length;
      },
      scope () {
        return this.tabs[this.tab].scope;
      },
      modeSelected () {
        // return this.mode !== undefined ? this.mode + 1 : 0
        return this.mode;
      },
      availableSteps () {
        const steps = [0];

        if (this.account && (this.usarComoTabelaGeral || this.user))
          steps.push(1);

        if (this.modeSelected && steps.includes(1))
          steps.push(2);

        if (this.files.length > 0 && steps.includes(2))
          steps.push(3);

        return steps;
      },
      wizardCompleted () {
        return this.tab === this.tabs.length - 1
      },
    },
    watch: {
      modeSelected: function (val) {
        if (!val) this.tabs[2].text = 'ação'
        else this.tabs[2].text = val
      },
    },
    mounted () {
      this.load();
    },
    methods: {
      load () {
        this.loadAccounts();
        this.$store.dispatch('loadUsers')
      },
      loadAccounts: async function() {
        this.accounts = [];
        const service = new AccountService();
        this.accounts = await service.getAllAccounts(false);
        // var result = await axios.get(`${baseApiUrl}accounts?all=1&detail=0&type=account`);
        // if (result) {
        //   result.data.data.forEach(ac => {
        //     this.accounts.push({
        //       id: ac.id,
        //       account: ac.account
        //     });
        //   });
        // }
      },
      reloadWizard () {
        location.reload()
        // Object.assign(this.$data, this.$options.data.apply(this))
        // this.tab = null
        // this.mode = null
      },
      showLoader (state) {
        if (state) {
          this.fileIsLoading = true
          this.fileLoaderIsDisabled = true
        } else {
          this.fileIsLoading = false
          this.fileLoaderIsDisabled = false
        }
      },
      next (valid) {
        if (!valid) return

        if (this.tab === this.tabs.length - 2) {
          this.showLoader(true)
          this.submitData((err) => {
            if (err && err.status >= 200 || err.status < 300) {
              this.tab++;
              return this.next(true);
            }
            this.showLoader(false)
          })
        } else if (this.tab === this.tabs.length - 1) {
          //do nothing
        } else {
          this.tab++
        }
      },
      onChange () {
        for (var i = 0; i < this.files.length - 1; i++ ) {
          var tamanho = this.files[i].size
        }
        console.log('TAMANHO ARQUIVOS', tamanho)
      },
      userSelected (usr) {
        console.log(usr)
        this.user = usr
      },
      async submitData (cb) {
        var data = new FormData();
        const scope = this.usarComoTabelaGeral ? 'accounts' : 'users';
        const id = scope === 'user' ? this.user : this.account;
  
        for (var i = 0; i <= this.files.length - 1; i++ ) {
          data.append('tables', this.files[i]);
        }
        data.append('description', this.description);
        data.append('scope', scope);
        data.append('id', id);
        data.append('product_type', this.type);
        
        delete axios2.defaults.headers.common['X-Requested-With'];
        axios2.defaults.headers.common['Content-Type'] = 'multipart/form-data';
        var response = null;
        try {
          response = await axios2.post(`${baseApiUrl}tables`, data);
          if (response) {
            this.updaloadFinished = true;
            this.$refs.observer.reset();
            cb(response);
          }
        } catch (result) {
          if (result) {
            //alert('Erro ao enviar tabelas');
            console.log('Erro enviando arquivo!', result);
            console.log({ error: result, response: null, status: 500 });
            cb(result);
          }
        } 
      },
    },
  }
</script>

<style lang="sass">
  .v-card.v-card.v-card--account
    border-color: currentColor
    border-width: 4px

    .v-icon
      color: inherit

  .v-card--account,
  .v-card--account:before
    border-radius: 50%

</style>
